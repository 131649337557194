@import '@bds/bds-core/constants/_constants.scss';
@import '@bds/bds-core/constants/_mixins.scss';

// Solve for these in bds-core?
mobile-menu .hrb-modal__overlay {
  justify-content: start;
  .hrb-modal__main .hrb-modal__content {
    padding: 0;
    padding-top: 20px;
  }
  .hrb-modal__header {
    display: none;
  }
}
.bdsp-standard-page {
  .bds-sideItem--container {
    padding: 0 20px;
  }
  .hrb-text--bullet-list li {
    list-style-position: outside;
  }
}

// Create utility class(es) to accomplish this?
.canvas {
  width: 100%;
  min-height: 200px;
}
.ptl-container {
  --bds-content-max-width: 100%;
  --bds-padding-inline: 24px;
  width: min(var(--bds-content-max-width), 100% - (var(--bds-padding-inline)));
  margin-inline: auto;
  @media screen and (min-width: 900px) {
    --bds-content-max-width: 100%;
    --bds-padding-inline: 32px;
    &.ptl-container-left {
      margin-inline: 0;
      padding-inline-start: var(--bds-padding-inline);
    }
    &.ptl-container-wide {
      --bds-content-max-width: 100%;
      --bds-padding-inline: 40px;
    }
  }
  @media screen and (min-width: 1200px) {
    --bds-content-max-width: 1123px;
    --bds-padding-inline: 104px;
  }
  @media screen and (min-width: 1500px) {
    --bds-content-max-width: 1226px;
    --bds-padding-inline: 104px;
  }
}

.ptl-grid-item {
  cursor: pointer;
}
.ptl-use-case-description {
  max-width: 400px;
  text-align: justify;
  text-justify: inter-word;
}

/* BWO Copilot MVP */

#rwu_help-panel {
  .hrb-nav-item .hrb-nav-icon--right .hrb-nav-item--text-icon {
    flex-direction: column;
  }
  .hrb-navbar-section .hrb-nav .hrb-navbar-nav {
    padding: 0;
  }
  .hrb-nav-item {
    .hrb-nav-item--text-icon .hrb-icon {
      width: 20px;
    }
    .hrb-nav-link {
      .hrb-nav-item--text-icon {
        color: green;
      }
      .hrb-text {
        font-size: 14px;
        color: green;
      }
      &:hover {
        color: green;
      }
    }
  }
  .hrb-input {
    border-radius: 16px 0 0 16px;
    border-right: 0;
  }
  .rwu_help-panel-header-input {
    .hrb-button {
      button {
        height: 80px;
        width: 80px;
        padding: 0;
        border-radius: 0 16px 16px 0;
        gap: 0;
      }
    }
  }
}
